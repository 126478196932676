<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary white--text text-h5">
                        Update manual {{ this.manualName }}
                    </v-toolbar>
                    <ASuccessThree class="mt-2" :api="api"/>
                    <v-text-field
                    class="mt-5"
                    dense outlined
                    v-model="form.name"
                    label="Manual name">

                    </v-text-field>
                    <v-text-field v-model="form.description" dense counter="100"
                    outlined label="description">

                    </v-text-field>
                    <div class="d-flex justify-end">
                        <v-btn color="primary" plain @click="redirectBack">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="validateInput" :loading="api.isLoading">
                            Submit
                        </v-btn>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessThree from '../../components/common/ASuccessThree.vue';
export default {
    components:{
    AConfirmation,
    ASuccessThree
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        manualName:null,
        form:{
            name:null,
            description:null,
        },
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:"",
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getManual"){
                this.form.name = resp.data.name;
                this.form.description = resp.data.description;
            }
            if(resp.class=="updateManual"){
                this.api.success= "Succesfully update the manual"                
                this.api.isSuccesful = true;

            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();   
    },
    methods: {
        fetch(){
            let fetchManualApi = this.fetchManual();
            this.$api.fetch(fetchManualApi);

        },
        fetchManual(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateManualApi = this.update();
            this.$api.fetch(updateManualApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        update(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="PUT";
            tempApi.params = this.form;
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/"+this.$router.history.current.params.id;
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        }
    }
}
</script>